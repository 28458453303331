import React, { useContext, useState, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import Layout from '../layout'
import { ThemeProvider } from '@material-ui/styles'
import { WistiaVideoOverlay } from '@system'
import { Box, CssBaseline } from '@material-ui/core'
import ACC_THEME from '../../themes'
import PageContext from '@context'
import isInBrowser from '@helpers/is-in-browser'

const WistiaAutoplayPopup = (props) => {
  const { metaData } = props.blok
  const [shouldPresentAndPlay, setshouldPresentAndPlay] = useState(false)
  const [wistiaVideoId, setWistiaVideoId] = useState(false)

  const pageContext = useContext(PageContext)

  useEffect(() => {
    if (isInBrowser) {
      const params = new URLSearchParams(
        !!pageContext && pageContext.location
          ? pageContext.location.search
          : window.location.search
      )
      const shouldAutoLoad = params.get('watch') === 'true'
      const _wistiaVideoId = params.get('vid')

      setshouldPresentAndPlay(shouldAutoLoad)
      setWistiaVideoId(_wistiaVideoId)
    }
  }, [pageContext])

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <SbEditable content={props.blok}>
          {shouldPresentAndPlay && !!wistiaVideoId && (
            <Box>
              <WistiaVideoOverlay videoId={wistiaVideoId} />
            </Box>
          )}
        </SbEditable>
      </ThemeProvider>
    </Layout>
  )
}

export default WistiaAutoplayPopup
